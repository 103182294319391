import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 95.7 77.9"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M87 1.1a4 4 0 0 0-5.6 0L31.9 50.6a4 4 0 0 1-5.6 0L14.1 38.4a4 4 0 0 0-5.6 0l-7.4 7.5a4 4 0 0 0 0 5.6l25.3 25.3a4 4 0 0 0 5.6 0l62.6-62.6a4 4 0 0 0 0-5.6z" }, null, -1)
  ])))
}
export default { render: render }